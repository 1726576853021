import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CookieService} from '../shared/services/cookie.service';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
    constructor(private cookieService: CookieService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!request.headers.has('Authorization') && this.cookieService.getEnocSession()) {
            request = request.clone({
                setHeaders: {
                    Authorization: this.cookieService.getEnocSession(),
                },
            });
        }
        return next.handle(request);
    }
}
