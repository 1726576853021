import {Injectable} from '@angular/core';
import {DomainAwarenessService} from 'ngx-shared-services';
import {environment} from '../../environments/environment';
import {APIEndpoints} from '../../environments/environment.interface';

@Injectable({providedIn: 'root'})
export class ApiEndpointsService implements APIEndpoints {
    private readonly endpoints: APIEndpoints;

    constructor(private domainAwarenessService: DomainAwarenessService) {
        const {brand} = this.domainAwarenessService;
        this.endpoints = environment.brands[brand] || environment.brands[environment.defaults.brand];
    }

    get baseUrl(): string {
        return this.endpoints.baseUrl;
    }

    get globalNavUrl() {
        return document.location.origin + '/api/v1/user/menu';
    }
}
