import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';

/**
 * Wrapping the translateService for Enel purposes, including fallback rules, branding, etc
 */
@Injectable()
export class CookieService {
    cookies: any;

    constructor(@Inject(DOCUMENT) private document: any) {
        this.cookies = {};
        this.document.cookie.split(';').map(variable => {
            const variableParts = variable.split('=');
            this.cookies[variableParts[0].trim()] = variableParts[1];
        });
    }

    // Yeah need to import the angular cookie service
    getCookie(cname, defaulter = null) {
        return this.cookies[cname] || defaulter;
    }

    getEnocSession() {
        return this.getCookie('enoc_session');
    }

    getMarketsMock() {
        return this.getCookie('markets_mock');
    }

    getLocale() {
        return this.getCookie('locale');
    }

    getBrand() {
        return this.getCookie('default_brand');
    }
}
