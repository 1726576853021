import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Permission } from './auth-guard.service';

const appName = 'UTILITY_SERVICE_ACCOUNT_MANAGEMENT';

@Injectable()
export class UserService {
  redirectUrl: string;
  private permissions: Permission[];

  constructor(private apiService: ApiService) {
    this.apiService = apiService;
  }

  /**
   * Get the Permissions for the Markets app that the logged in User has.
   *
   * @returns {Promise<User>}
   */
  async getPermissions(): Promise<Permission[]> {
    if (this.permissions && this.permissions.length > 0) {
      return this.permissions;
    } else {
      try {
        const permissions = await this.apiService.get('user/permissions');
        this.permissions = permissions && permissions[appName] ? permissions[appName] : [];
        return this.permissions;
      } catch (err) {
        console.log('Error: UserService - could not fetch user permissions');
        console.log(JSON.stringify(err));
        throw err;
      }
    }
  }
}
