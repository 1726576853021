import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import { Context, ContextSelectorService } from 'ngx-global-nav';

@Injectable()
export class OrganizationsService {
  isInit = true;
  readonly forcedOrg = new BehaviorSubject<any>(null);
  readonly orgs$ = new BehaviorSubject<[]>(null);
  readonly currentOrg$ = new BehaviorSubject<any>(null);
  loadingOrgs$ = new BehaviorSubject<boolean>(null);

  constructor(private apiService: ApiService, private contextSelectorService: ContextSelectorService) {
    this.contextSelectorService.currentContext$.subscribe((contexts: Context[]) => {
      this.currentOrg$.next(contexts[0]);
    });
  }

  async getOrgs(forceDefaultOrgId?) {
    if (this.isInit) {
      try {
        this.loadingOrgs$.next(true);
        const orgs = await this.apiService.get(`orgs`);
        this.orgs$.next(orgs);
        this.loadingOrgs$.next(false);
        this.contextSelectorService.setContextList(orgs, forceDefaultOrgId);
      } catch (err) {
        this.loadingOrgs$.next(false);
        console.log(`Could not load Organizations.`, err);
      }
    }
    this.isInit = false;
  }
}
