<div class="app-service-delivery-point-view content">
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon *ngIf="!loadingServiceDeliveryPoint">ballot</mat-icon>
                    <mat-spinner *ngIf="loadingServiceDeliveryPoint" matSuffix diameter="30" color="warn"></mat-spinner>
                </div>
                <h1
                    id="view-title"
                    class="ellipsis"
                    data-automation="name"
                    title="{{ serviceDeliveryPoint.displayLabel }}"
                >
                    <ng-container *ngIf="!loadingServiceDeliveryPoint">{{
                        serviceDeliveryPoint.displayLabel
                    }}</ng-container>
                    <ng-container *ngIf="loadingServiceDeliveryPoint">{{
                        'utility.account.loading' | translate
                    }}</ng-container>
                </h1>
                <p class="subtitle" data-automation="subtitle">{{ 'sdp.view.subtitle' | translate }}</p>
            </div>
        </div>
        <div class="right-column">
            <button
                class="icon-btn x-btn-secondary"
                color="accent"
                aria-label="delete"
                data-automation="serviceDeliveryPointDelete"
            >
                <mat-icon>delete</mat-icon>
                <span class="btn-text"> {{ 'utility.account.button.delete' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                color="accent"
                aria-label="Edit"
                (click)="handleEdit()"
                data-automation="serviceDeliveryPointEdit"
            >
                <mat-icon>edit</mat-icon>
                <span class="btn-text"> {{ 'utility.account.button.edit' | translate }}</span>
            </button>
        </div>
    </header>

    <form #view="ngForm" class="service-delivery-point-form">
        <div class="form-group" data-automation="labels">
            <div class="view-field-container small">
                <mat-label class="field-label" data-automation="name"
                    >{{ 'sdp.list.table.heading.name' | translate }}:</mat-label
                >
                <div class="field-value">
                    {{ serviceDeliveryPoint.displayLabel || '-' }}
                </div>
            </div>
            <div class="view-field-container small">
                <mat-label class="field-label" data-automation="locale"
                    >{{ 'utility.account.locale' | translate }}:</mat-label
                >
                <div class="field-value">
                    {{ getLocaleDisplayLabel() }}
                </div>
            </div>
            <div class="view-field-container small">
                <mat-label class="field-label" data-automation="timezone"
                    >{{ 'utility.account.timezone' | translate }}:</mat-label
                >
                <div class="field-value">
                    {{ serviceDeliveryPoint.timezone || '-' }}
                </div>
            </div>
            <div class="view-field-container small">
                <mat-label class="field-label" data-automation="commodity"
                    >{{ 'utility.account.commodity' | translate }}:</mat-label
                >
                <div class="field-value">
                    {{ serviceDeliveryPoint.commodity || '-' }}
                </div>
            </div>
            <div class="view-field-container small">
                <mat-label class="field-label" data-automation="sdpType"
                    >{{ 'utility.account.type' | translate }}:</mat-label
                >
                <div class="field-value">
                    {{ serviceDeliveryPoint.sdpType || '-' }}
                </div>
            </div>
            <div class="view-field-container small">
                <mat-label class="field-label" data-automation="utilityMeterNumber"
                    >{{ 'utility.account.utility_meter_number' | translate }}:</mat-label
                >
                <div class="field-value">
                    {{ serviceDeliveryPoint.utilityMeterNumber || '-' }}
                </div>
            </div>
            <div class="view-field-container small">
                <mat-label class="field-label" data-automation="startDttm"
                    >{{ 'utility.account.start_dttm' | translate }}:</mat-label
                >
                <div class="field-value">
                    {{ serviceDeliveryPoint.startDttm | date: 'longDate':'':locale }}
                </div>
            </div>

            <div class="view-field-container small">
                <mat-label class="field-label" data-automation="endDttm"
                    >{{ 'utility.account.end_dttm' | translate }}:</mat-label
                >
                <div class="field-value">
                    {{ serviceDeliveryPoint.endDttm | date: 'longDate':'':locale }}
                </div>
            </div>
            <div class="view-field-container small">
                <mat-label class="field-label" data-automation="status"
                    >{{ 'utility.account.status' | translate }}:</mat-label
                >
                <div class="field-value">
                    {{ getStatusDisplayLabel() | translate }}
                </div>
            </div>
            <div class="view-field-container small nowrap">
                <mat-label class="field-label" data-automation="description"
                    >{{ 'utility.account.description' | translate }}:</mat-label
                >
                <div class="field-value nowrap">
                    {{ serviceDeliveryPoint.description || '-' }}
                </div>
            </div>
        </div>
    </form>
    <div class="utility-account">
        <div class="form-header">
            <h1>{{ 'utility.account.utility_accounts' | translate }}</h1>
            <div class="create-button-container">
                <!-- <button id="create-button" class="icon-btn x-btn-primary" color="accent">
                    <mat-icon>add</mat-icon>
                    <span class="btn-text">{{ 'sdp.list.button.create_service_delivery_point' | translate }}</span>
                </button> -->
            </div>
        </div>
        <div class="ua-list">
            <mat-list *ngIf="serviceAccountList.length > 0">
                <mat-list-item *ngFor="let SA of serviceAccountList">
                    <h3 matLine>{{ SA.displayLabel }}</h3>
                </mat-list-item>
            </mat-list>
        </div>
        <div *ngIf="serviceAccountList.length <= 0" class="no-utility-accounts">
            <mat-icon>close</mat-icon>
            <h1>{{ 'utility.account.view.no_utility_accounts' | translate }}</h1>
            <h3 class="no-ua-configured">{{ 'utility.account.view.no_accounts_configured' | translate }}</h3>
        </div>
    </div>
</div>
