import {Injectable} from '@angular/core';
import * as convertKeys from 'convert-keys';
import {Status} from '../model/status.model';
import {ApiService} from './api.service';

@Injectable()
export class StatusesService {
    statuses: Status[];

    constructor(private apiService: ApiService) {
        this.statuses = [];
    }

    async setStatuses() {
        const rawResp = await this.apiService.get('statuses');
        if (this.statuses && Array.isArray(this.statuses)) {
            while (this.statuses.length > 0) {
                this.statuses.pop();
            }
        }
        if (typeof rawResp !== undefined && Array.isArray(rawResp) && rawResp.length > 0) {
            rawResp.sort((a, b) => Number(a.display_seq) - Number(b.display_seq));
            this.statuses.push(...convertKeys.toCamel<Status[]>(rawResp));
        }
    }
}
