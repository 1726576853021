import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';

export interface ServiceDeliveryPointData {
    serviceDeliveryPointId: string;
}

@Component({
    selector: 'app-delete-dialog',
    templateUrl: './delete-dialog.component.html',
    styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteDialogComponent {
    dialogState = 'DEFAULT';

    constructor(
        private router: Router,
        public dialogRef: MatDialogRef<DeleteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ServiceDeliveryPointData,
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    async handleDelete() {
        console.log('DELETE', this.data);
        try {
            this.dialogState = 'DELETING';
            // TODO: deleteUser needed
            // await this.serviceDeliveryPointService.deleteServiceDeliveryPoint(this.data.serviceDeliveryPointId);
            this.dialogState = 'DELETE_SUCCESS';
            setTimeout(() => {
                this.dialogRef.close();
                this.router.navigate([`list`], {});
            }, 2000);
        } catch (e) {
            this.dialogState = 'DELETE_FAILED';
            console.log(e);
            setTimeout(() => {
                this.dialogRef.close();
            }, 2000);
        }
    }
}
