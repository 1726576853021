import { Component, OnInit } from '@angular/core';
import { ServiceDeliveryPointService } from '../shared/services/service-delivery-points.service';
import { Router } from '@angular/router';
import { OrganizationsService } from '../shared/services/organizations.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class DefaultComponent implements OnInit {
  loading = true;
  noServiceDeliveryPoints = false;
  serviceDeliveryPoints;

  constructor(
    private router: Router,
    private serviceDeliveryPointService: ServiceDeliveryPointService,
    private organizationsService: OrganizationsService,
  ) {
    if (this.organizationsService.isInit) {
      this.organizationsService.getOrgs();
    }
    this.serviceDeliveryPointService.selectedServiceDeliveryPointId$.next(null);
  }

  isEdit() {
    return this.router.url.includes('edit');
  }
  isCreate() {
    return this.router.url.includes('create');
  }
  isView() {
    return this.router.url.includes('view');
  }
  navigateToFirstServiceDeliveryPoint(serviceDeliveryPoints) {
    if (serviceDeliveryPoints && serviceDeliveryPoints.length > 0) {
      this.loading = true;
      this.noServiceDeliveryPoints = false;
      this.serviceDeliveryPointService.selectedServiceDeliveryPointId$.next(serviceDeliveryPoints[0].id);
    } else {
      this.loading = false;
      this.noServiceDeliveryPoints = true;
    }
  }

  async ngOnInit() {
    // refresh asset list
    this.serviceDeliveryPointService.serviceDeliveryPoints$.subscribe(serviceDeliveryPoints => {
      this.serviceDeliveryPoints = serviceDeliveryPoints;
      if (!this.isCreate() && !this.isEdit()) {
        this.navigateToFirstServiceDeliveryPoint(serviceDeliveryPoints);
      }
    });
  }
}
