import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, GlobalNavService, Context } from 'ngx-global-nav';
import { AnalyticsService, CookieService as NgxCookieService } from 'ngx-shared-services';
import { environment } from 'src/environments/environment.prod';
import { ApiEndpointsService } from './services/api-endpoints.service';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from './shared/services/sidenav.service';
import { Router } from '@angular/router';
import { ApiService } from './shared/services/api.service';
import { SiteService } from './shared/services/sites.service';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { NavService } from './shared/services/global-nav.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('sidenav') public sidenav: MatSidenav;

  programs: any[];
  sidenavOpen = true;
  selectedProgramId: string;
  isInit = true;
  orgList: Context[] = [];
  resizeObservable$: Observable<Event>;
  resizeSubScription$: Subscription;
  orgContext$: Observable<Context>;
  navUrl = '';

  constructor(
    private router: Router,
    private translate: TranslateService,
    private apiEndpointsService: ApiEndpointsService,
    private apiService: ApiService,
    private globalNavService: GlobalNavService,
    private navService: NavService,
    private analytics: AnalyticsService,
    private authService: AuthService,
    private cookieService: NgxCookieService,
    private sidenavService: SidenavService,
    private siteService: SiteService,
  ) {
    this.siteService.sites$.subscribe(sites => {});
    this.getNavUrl();
  }

  ngOnInit(): void {
    const { defaults } = environment;
    // i18n - cobranding stuff
    const locale = this.cookieService.getI18NLocale() || defaults.locale;
    const brand = this.cookieService.getI18NBrand() || defaults.brand.toUpperCase();
    this.translate.setDefaultLang(`${defaults.locale}.${defaults.brand.toUpperCase()}`);
    this.translate.use(`${locale}.${brand}`).subscribe(() => {
      this.translate.get('APP.TITLE').subscribe(result => {
        document.title = result;
      });
    });
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubScription$ = this.resizeObservable$.subscribe(event => {
      if (window.innerWidth <= 1280) this.sidenavService.close();
    });
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }

  async getNavUrl() {
    this.navUrl = await this.navService.getNavUrl();
  }

  isLargeScreen() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width > 1280;
  }
}
