export interface ServiceDeliveryPoint {
  id: string;
  address1: string;
  address2: string;
  alternateIds: {};
  associatedClassicChildNodeIds: ['string'];
  city: string;
  commodity: string;
  country: string;
  defaultLocale: string;
  descriptions: {};
  description: string;
  displayLabels: {};
  displayLabel: string;
  endDttm: '2020-11-18T05:23:55.236Z';
  postalCode: string;
  sdpType: 'PPA';
  shortDisplayLabels: {};
  spaceType: string;
  startDttm: '2020-11-18T05:23:55.236Z';
  state: string;
  status: string;
  timezone: string;
  utilityMeterNumber: string;
  siteId: string;
}

export let statuses = [
  {
    id: 'ACTIVE',
    display_label: 'utility.account.statuses.active',
  },
  {
    id: 'INACTIVE',
    display_label: 'utility.account.statuses.inactive',
  },
];

export interface SDPsDataObject {
  sdps: ServiceDeliveryPoint[];
  hasError: boolean;
  errorMessage?: string;
  selectFirst?: boolean;
}
