<ng-container *ngIf="noServiceDeliveryPoints">
    <div class="empty-page">
        <mat-icon>warning</mat-icon>
        <h2> {{ 'sdp.list.none_configured' | translate }}</h2>
    </div>
</ng-container>

<ng-container *ngIf="loading">
    <div class="empty-page">
        <mat-spinner diameter="60"></mat-spinner>
        <h2>{{ 'service.delivery.account.loading' | translate }}...</h2>
    </div>
</ng-container>
