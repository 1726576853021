import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';

// Translate
import {TranslateModule} from '@ngx-translate/core';
import {ApiService} from './services/api.service';
import {CookieService} from './services/cookie.service';
import {GlobalAlertService} from './services/global-alert.service';
import {UserService} from './services/user.service';
import {ServiceDeliveryPointService} from './services/service-delivery-points.service';

@NgModule({
    declarations: [],
    exports: [BrowserModule, CommonModule, TranslateModule, FormsModule],
    providers: [ApiService, UserService, CookieService, GlobalAlertService, ServiceDeliveryPointService],
})
export class SharedModule {
}
