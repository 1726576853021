import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import * as convertKeys from 'convert-keys';
import {ApiService} from './api.service';
import {Site} from '../model/site.model';

@Injectable()
export class TypeService {
    conflictError = new Subject<any[]>();
    readonly types$ = new BehaviorSubject<Site[]>(null);
 
    constructor(private apiService: ApiService) {
    }

    async setTypes() {
        try {
            const types = await this.apiService.get('sdp-types');
            this.types$.next(convertKeys.toCamel(types));
        } catch (err) {
            console.log(`Could not load Types`, err);
            this.types$.next([]);
        }
    }
}
