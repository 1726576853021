import {Injectable} from '@angular/core';
import * as convertKeys from 'convert-keys';
import {Locale} from '../model/locale.model';
import {ApiService} from './api.service';
import {Subject} from 'rxjs';

@Injectable()
export class LocalesService {
    locales$: Subject<Locale[]>;

    constructor(private apiService: ApiService) {
        this.locales$ = new Subject<Locale[]>();
    }

    async setLocales() {
        const locales = await this.apiService.get('locales');
        this.locales$.next(convertKeys.toCamel(locales));
    }

    checkForLocale(locale_key: string, locales: Locale[]) {
        return locales.find(locale => locale.localeName === locale_key);
    }
}
