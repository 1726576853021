import { GlobalAlertService } from './global-alert.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import * as convertKeys from 'convert-keys';
import { ApiService } from './api.service';
import { ServiceAccount } from '../model/service-account.model';
import { SDPsDataObject, ServiceDeliveryPoint } from '../model/service-delivery-point.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ServiceDeliveryPointService {
  conflictError = new Subject<any[]>();
  readonly serviceDeliveryPoints$ = new BehaviorSubject<SDPsDataObject>({ sdps: [], hasError: false });
  readonly serviceDeliveryPoint$ = new BehaviorSubject<ServiceDeliveryPoint>(null);
  readonly selectedServiceDeliveryPointId$ = new BehaviorSubject<string>(null);
  sdpParentId: any;
  readonly serviceAccounts$ = new BehaviorSubject<ServiceAccount[]>(null);

  constructor(
    private apiService: ApiService,
    private messageService: GlobalAlertService,
    private translateService: TranslateService,
  ) { }

  async getServiceDeliveryPoint(id: string) {
    try {
      const serviceDeliveryPoint: any = await this.apiService.get(`service-delivery-points/${id}`);
      const sdp: ServiceDeliveryPoint = convertKeys.toCamel(serviceDeliveryPoint);
      this.serviceDeliveryPoint$.next({
        ...sdp,
        displayLabels: serviceDeliveryPoint.display_labels,
        descriptions: serviceDeliveryPoint.descriptions
      });
      this.selectedServiceDeliveryPointId$.next(id);
      return serviceDeliveryPoint;
    } catch (err) {
      console.log('Could not load serviceDeliveryPoint.', err);
      const GET_SDP_ERROR = this.translateService.instant('utility.account.get.sdp.error');
      this.messageService.setError(GET_SDP_ERROR);
      throw err;
    }
  }

  setSdpParentId(value) {
    this.sdpParentId = value;
  }

  getSdpParentId() {
    return this.sdpParentId;
  }

  async getServiceDeliveryPoints(siteId: string, isUpdatingList = true, forceSelectFirst = true) {
    try {
      const sdps = await this.apiService.get(`sites/${siteId}/service-delivery-points`);
      isUpdatingList && this.serviceDeliveryPoints$.next({
        sdps: convertKeys.toCamel(sdps),
        hasError: false,
        selectFirst: forceSelectFirst,
      });
      return sdps;
    } catch (err) {
      console.log('Could not load SDPS.', err);
      const GLOBAL_GET_SDPS_ERROR = this.translateService.instant('utility.account.get.sdps.global-error');
      const LIST_GET_SDPS_ERROR = this.translateService.instant('utility.account.get.sdps.error');
      isUpdatingList && this.serviceDeliveryPoints$.next({
        sdps: [],
        hasError: true,
        errorMessage: LIST_GET_SDPS_ERROR
      });
      this.messageService.setError(GLOBAL_GET_SDPS_ERROR);
      throw err;
    }
  }

  async getSDPServiceAccounts(sdpId: string) {
    try {
      const serviceAccs = await this.apiService.get(`service-delivery-points/${sdpId}/service-accounts`);
      this.serviceAccounts$.next(convertKeys.toCamel(serviceAccs));
    } catch (err) {
      console.log(`Could not reterive Service Accounts from SDP with id: ${sdpId}`);
      throw err;
    }
  }

  async createServiceDeliveryPoint(
    serviceDeliveryPoint: ServiceDeliveryPoint,
    siteId: string,
  ): Promise<ServiceDeliveryPoint> {
    try {
      const dto = convertKeys.toSnake<any>(serviceDeliveryPoint);
      dto.display_labels = serviceDeliveryPoint.displayLabels;
      dto.descriptions = serviceDeliveryPoint.descriptions;
      dto.address1 = serviceDeliveryPoint.address1;
      delete dto.address_1;
      if (dto.address_2) {
        dto.address2 = serviceDeliveryPoint.address2;
      }
      delete dto.address_2;
      const createdServiceDeliveryPoint = await this.apiService.post('service-delivery-points', dto);
      const relationships = {
        related_id: createdServiceDeliveryPoint.id,
        space_id: siteId,
      };
      await this.apiService.post('relationships', relationships);
      return convertKeys.toCamel<any>(createdServiceDeliveryPoint);
    } catch (err) {
      console.log(`Could not Create ServiceDeliveryPoint`, err);
      throw err;
    }
  }

  async deleteServiceDeliveryPoint(serviceDeliveryPointId: string, siteId: string) {
    const deletedServiceDeliveryPoint = await this.apiService.delete(
      `sites/${siteId}/service-delivery-points/${serviceDeliveryPointId}`,
    );
    return convertKeys.toCamel<any>(deletedServiceDeliveryPoint);
  }

  async updateServiceDeliveryPoint(serviceDeliveryPoint: ServiceDeliveryPoint, siteId: string, selectedSite) {
    try {
      const serviceDeliveryPointId = serviceDeliveryPoint.id;
      const dto = convertKeys.toSnake<any>(serviceDeliveryPoint);
      dto.descriptions = serviceDeliveryPoint.descriptions;
      dto.display_labels = serviceDeliveryPoint.displayLabels;
      const updatedServiceDeliveryPoint = await this.apiService.put(
        `service-delivery-points/${serviceDeliveryPointId}`,
        dto,
      );
      if (siteId !== selectedSite) {
        await this.apiService.delete(`relationships/${selectedSite}/${serviceDeliveryPointId}`);
        const relationships = {
          related_id: serviceDeliveryPointId,
          space_id: siteId,
        };
        await this.apiService.post('relationships', relationships);
      }

      return convertKeys.toCamel<any>(updatedServiceDeliveryPoint);
    } catch (err) {
      console.log(`Could not Update ServiceDeliveryPoint`, err);
      throw err;
    }
  }
}
