<div class="app-service-delivery-point-create content">
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon>library_add</mat-icon>
                </div>
                <h1 id="create-title" data-automation="pageTitle">
                    {{ 'utility.account.create.page_title' | translate }}
                </h1>
                <p class="subtitle ellipsis" data-automation="pageSubtitle">
                    {{ 'utility.account.create.subtitle' | translate }}
                </p>
            </div>
        </div>

        <div class="right-column">
            <button
                class="icon-btn x-btn-secondary"
                aria-label="Cancel"
                (click)="handleCancel()"
                data-automation="serviceDeliveryPointCancel"
            >
                <mat-icon>clear</mat-icon>
                <span class="btn-text">{{ 'utility.account.button.cancel' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                aria-label="Submit"
                (click)="handleSubmit()"
                [disabled]="!serviceDeliveryPointForm.valid"
                type="submit"
                data-automation="serviceDeliveryPointSubmit"
            >
                <mat-icon *ngIf="!isSubmitting">done</mat-icon>
                <div *ngIf="isSubmitting" class="btn-spinner">
                    <mat-spinner diameter="15"></mat-spinner>
                </div>
                <span class="btn-text"> {{ 'utility.account.button.submit' | translate }}</span>
            </button>
        </div>
    </header>

    <form #createForm="ngForm" data-app-prefix="prt" class="service-delivery-point-form">
        <app-service-delivery-point-form
            [mode]="mode"
            [serviceDeliveryPointForm]="serviceDeliveryPointForm"
        ></app-service-delivery-point-form>
    </form>
</div>
