import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import * as convertKeys from 'convert-keys';
import { ApiService } from './api.service';
import { Site } from '../model/site.model';

@Injectable()
export class SiteService {
  conflictError = new Subject<any[]>();
  readonly sites$ = new BehaviorSubject<Site[]>([]);
  readonly selectedSite$ = new BehaviorSubject<string>(null);
  loadingSites$ = new BehaviorSubject<boolean>(true);
  siteSelection$ = new BehaviorSubject<string>(null);
  isFirst = true;
  constructor(private apiService: ApiService) {}

  async getSites(orgId: string) {
    try {
      this.loadingSites$.next(true);
      const { data: sites } = await this.apiService.get(`organizations/${orgId}/sites`);
      this.sites$.next(convertKeys.toCamel(sites));
      this.loadingSites$.next(false);
      return sites;
    } catch (err) {
      console.log(`Could not load Sites`, err);
      this.sites$.next([]);
    }
  }

  getSitesList() {
    return this.sites$;
  }

  getSelectedSite() {
    return this.selectedSite$;
  }

  setSelectedSite(siteID) {
    this.selectedSite$.next(siteID);
  }
  selectSite(siteID) {
    this.siteSelection$.next(siteID);
  }

  getIsFirst() {
    return this.isFirst;
  }

  setIsFirst(value: boolean) {
    this.isFirst = value;
  }
}
