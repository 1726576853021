import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CreateComponent} from './create/create.component';
import {EditComponent} from './edit/edit.component';
import {ViewComponent} from './view/view.component';
import {DefaultComponent} from './default/default.component';
import {AuthGuard, Permission} from './shared/services/auth-guard.service';

export enum Routing {
    doc = 'doc',
    list = 'list',
    configured = 'configured',
    create = 'create',
    history = 'history',
    root = '',
    view = 'details/:id/view',
    edit = 'details/:id/edit',
}

const routes: Routes = [
    {
        path: Routing.root,
        component: DefaultComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard],
        data: {requiredPermissions: [Permission.read]},
    },
    {
        path: Routing.create,
        component: CreateComponent,
        canActivate: [AuthGuard],
        data: {requiredPermissions: [Permission.create]},
    },
    {
        path: Routing.edit,
        component: EditComponent,
        canActivate: [AuthGuard],
        data: {requiredPermissions: [Permission.update]},
    },
    {
        path: Routing.view,
        component: ViewComponent,
        canActivate: [AuthGuard],
        data: {requiredPermissions: [Permission.read]},
    },
    {
        path: Routing.doc,
        redirectTo: '/doc/index.html',
    },
    {
        path: '**',
        redirectTo: '/' + Routing.root,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [AuthGuard],
})
export class AppRoutingModule {
}
