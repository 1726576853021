import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {GlobalAlertComponent} from './global-alert.component';

import {SharedModule} from '../shared/shared.module';

@NgModule({
    declarations: [GlobalAlertComponent],
    exports: [GlobalAlertComponent],
    imports: [BrowserModule, SharedModule],
    bootstrap: [GlobalAlertComponent],
})
export class GlobalAlertModule {
}
