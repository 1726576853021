import { OrganizationsService } from './../shared/services/organizations.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidenavService } from '../shared/services/sidenav.service';
import { SiteService } from '../shared/services/sites.service';
import { ServiceDeliveryPointService } from '../shared/services/service-delivery-points.service';
import { Context, ContextSelectorService } from 'ngx-global-nav';
import { Site } from '../shared/model/site.model';
import { UtilityAccountsService } from '../shared/services/utility-accounts.service';
import { Subscription } from 'rxjs';
import { UtilityAccount } from '../shared/model/utility-account';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  loadingSites = true;
  loadingSDPs = false;
  noSDPs = false;
  siteSelected = false;
  spdList: any[];
  sites: Site[] = [];
  selectedSDPId = '';
  isInit = true;
  selectedSiteId: string;
  errorLoadingSDPs = { message: '', hasError: false };
  private subscriptions: Subscription[] = [];
  utilityAccounts: UtilityAccount[] = [];
  TO_BE_CONFIGURED = 'yet to be configured';

  get sitesL() {
    return this.siteService.sites$;
  }

  get loadingOrgs$() {
    return this.organizationsService.loadingOrgs$;
  }

  constructor(
    private router: Router,
    private siteService: SiteService,
    private sidenavService: SidenavService,
    private orgSelectorService: ContextSelectorService,
    private organizationsService: OrganizationsService,
    private SDPService: ServiceDeliveryPointService,
    private utilityAccountsService: UtilityAccountsService,
    private translateService: TranslateService,
  ) {
    this.utilityAccountsService.getUtilityAccounts();
    const utilityAccountsSub = this.utilityAccountsService.utilityAccounts$.subscribe(utitlityAccounts => {
      this.TO_BE_CONFIGURED = this.translateService.instant('utility.account.sdp.service-accounts.not-configured');
      this.utilityAccounts = utitlityAccounts;
      if (utitlityAccounts) {
        const withids = this.utilityAccounts.filter(ua => ua.alternateIds != undefined);
      }
    });
    const orgsSub = this.orgSelectorService.currentContext$.subscribe(async (orgs: Context[]) => {
      const org = orgs[0];
      this.selectedSiteId = '';
      this.spdList = [];
      this.errorLoadingSDPs.message = '';
      this.errorLoadingSDPs.hasError = false;
      if (org && org.id) {
        this.loadingSites = true;
        await this.siteService.getSites(org.id);
        if (!this.router.isActive('/', true) && !this.router.isActive('/create', true) && !this.isInit) {
          this.router.navigate([`/`], {});
        }
        this.isInit = false;
      }
    });

    const sitesSub = this.siteService.sites$.subscribe(sites => {
      if (sites) {
        this.selectedSDPId = '';
        this.siteSelected = false;
        this.loadingSites = false;
        this.sites = sites;
      }
    });

    const sdpsSub = this.SDPService.serviceDeliveryPoints$.subscribe(data => {
      const shouldSelectFirst = data.selectFirst || false;
      if (data && !data.hasError) {
        this.spdList = data.sdps;
        this.noSDPs = data.sdps.length === 0;
        this.errorLoadingSDPs.message = '';
        this.errorLoadingSDPs.hasError = false;
      }
      if (data.hasError) {
        this.errorLoadingSDPs.message = data.errorMessage;
        this.errorLoadingSDPs.hasError = true;
      }
      this.loadingSDPs = false;
      if (this.spdList.length > 0 && shouldSelectFirst && this.selectedSiteId) {
        this.selectFirstSDP();
      } else if (this.spdList.length === 0 && shouldSelectFirst) {
        this.router.navigate([`/`], {});
      }
    });

    const sdpIdSub = this.SDPService.selectedServiceDeliveryPointId$.subscribe(async SDPId => {
      if (SDPId) {
        this.selectedSDPId = SDPId;
      }
    });

    const selectionSiteSub = this.siteService.siteSelection$.subscribe(siteId => {
      if (siteId && this.selectedSiteId !== siteId) {
        this.selectedSiteId = siteId;
        this.loadSdps({ value: siteId }, false);
      }
    });

    this.subscriptions = [orgsSub, sitesSub, sdpsSub, sdpIdSub, selectionSiteSub, utilityAccountsSub];
  }

  async ngOnInit() { }

  loadSdps($event, forceSelectFirstSite = true) {
    const shouldUpdateList = true;
    this.spdList = [];
    this.loadingSDPs = true;
    this.errorLoadingSDPs.message = '';
    this.errorLoadingSDPs.hasError = false;
    this.SDPService.getServiceDeliveryPoints($event.value, shouldUpdateList, forceSelectFirstSite);
    this.siteService.setSelectedSite($event.value);
    this.siteSelected = true;
    this.siteService.setIsFirst(forceSelectFirstSite);
  }

  getSDPStatus(sdpId) {
    const sdpUtilityAccounts = this.utilityAccounts.filter(
      utilityAccount => utilityAccount.serviceDeliveryPointId == sdpId,
    );
    if (sdpUtilityAccounts.length <= 0) {
      return 'AWAITING CONFIGURATION';
    }
    if (sdpUtilityAccounts.some(utilityAccount => utilityAccount.status == 'ACTIVE')) {
      return 'ACTIVE';
    }
    return 'INACTIVE';
  }

  isEdit() {
    return this.router.url.includes('edit');
  }
  isCreate() {
    return this.router.url.includes('create');
  }
  isView() {
    return this.router.url.includes('view');
  }

  getSDPActiveUtilityAccount(sdpId) {
    const activeUtitlityAccount = this.utilityAccounts.find(
      utilityAccount => utilityAccount.serviceDeliveryPointId == sdpId && utilityAccount.status == 'ACTIVE',
    );
    if (activeUtitlityAccount) {
      return activeUtitlityAccount.alternateIds && activeUtitlityAccount.alternateIds.serviceAccountNumber
        ? activeUtitlityAccount.alternateIds.serviceAccountNumber
        : this.TO_BE_CONFIGURED;
    }
    return '';
  }

  onRowClicked(id) {
    this.selectedSDPId = id;
    this.sidenavService.closeIfMobile();
    this.router.navigate([`details/${id}/view`], {});
  }

  selectFirstSDP() {
    const firstSDPId = this.spdList[0].id;
    this.selectedSDPId = firstSDPId;
    this.router.navigate([`details/${firstSDPId}/view`], {});
  }

  routeToCreatePage() {
    this.sidenavService.closeIfMobile();
    this.router.navigate([`create`], {});
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
