import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeleteDialogComponent } from '../dialogs/delete/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ServiceDeliveryPointService } from '../shared/services/service-delivery-points.service';
import { LocalesService } from '../shared/services/locales.service';
import { TimezonesService } from '../shared/services/timezones.service';
import { Timezone } from '../shared/model/timezone.model';
import { Locale } from '../shared/model/locale.model';
import {
  statuses as ServiceDeliveryPointStatus,
  ServiceDeliveryPoint,
} from '../shared/model/service-delivery-point.model';
import { CookieService } from '../shared/services/cookie.service';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { OrganizationsService } from '../shared/services/organizations.service';
import { Site } from '../shared/model/site.model';
import { ServiceAccount } from '../shared/model/service-account.model';
import { ContextSelectorService } from 'ngx-global-nav';
import { SiteService } from '../shared/services/sites.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss', '../shared/shared.styles.scss'],
})
export class ViewComponent implements OnInit, OnDestroy {
  form: FormGroup;
  serviceDeliveryPointId = '';
  subscriptions = [];
  locales: Locale[];
  timezones: Timezone[];
  statuses = ServiceDeliveryPointStatus;
  serviceDeliveryPointsList: ServiceDeliveryPoint[] = [];
  loadingServiceDeliveryPoint = false;
  serviceDeliveryPoint: ServiceDeliveryPoint;
  serviceAccountList: ServiceAccount[] = [];

  CONFLICT = 'There is a data conflict with this ServiceDeliveryPoint';
  readonly mode = 'view';

  constructor(
    private localesService: LocalesService,
    private timezonesService: TimezonesService,
    private cookieService: CookieService,
    private serviceDeliveryPointService: ServiceDeliveryPointService,
    private router: Router,
    private messageService: GlobalAlertService,
    private translateService: TranslateService,
    private organizationsService: OrganizationsService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public orgSelectorService: ContextSelectorService,
    public siteService: SiteService,
  ) {
    this.serviceDeliveryPoint = {} as any;

    this.translateService.get('service.delivery.account.notification.updated_successfully').subscribe(() => {
      this.CONFLICT = this.translateService.instant('service.delivery.account.notification.conflict');
    });
    this.timezones = timezonesService.timezones;
    this.localesService.locales$.subscribe(locales => {
      this.locales = locales;
    });
    this.loadDynamicLists();

    this.serviceDeliveryPointService.serviceDeliveryPoints$.subscribe(data => {
      if (data && !data.hasError) {
        this.serviceDeliveryPointsList = data.sdps;
      }
    });
    this.serviceDeliveryPointService.conflictError.subscribe(() => {
      this.messageService.setError(this.CONFLICT, 7000);
    });
  }

  ngOnInit() {
    this.form = new FormGroup({});
    this.route.params.subscribe(async params => {
      if (params.id) {
        this.serviceDeliveryPointId = params.id;
        this.loadingServiceDeliveryPoint = true;
        this.serviceDeliveryPointService.selectedServiceDeliveryPointId$.next(this.serviceDeliveryPointId);
        await this.serviceDeliveryPointService.getServiceDeliveryPoint(params.id);
        await this.serviceDeliveryPointService.getSDPServiceAccounts(params.id);
        if (this.organizationsService.isInit) {
          this.organizationsService.getOrgs();
        }
        this.loadingServiceDeliveryPoint = false;
      }
    });

    this.serviceDeliveryPointService.serviceDeliveryPoint$.subscribe(async serviceDeliveryPoint => {
      if (serviceDeliveryPoint) {
        this.serviceDeliveryPoint = serviceDeliveryPoint;
      }
    });
    this.serviceDeliveryPointService.serviceAccounts$.subscribe(async serviceAccs => {
      if (serviceAccs) {
        this.serviceAccountList = serviceAccs;
      }
    });

    this.orgSelectorService.currentContext$.subscribe(async orgs => {
      const org = orgs[0];
      const firstTime = this.siteService.getIsFirst();
      const selectedSite = this.siteService.getSelectedSite().getValue();
      if (this.serviceDeliveryPointId && org.id && firstTime && !selectedSite) {
        const site = await this.getParent(org.id, this.serviceDeliveryPointId);
        if (site) {
          this.siteService.setSelectedSite(site.id);
          this.siteService.selectSite(site.id);
          this.serviceDeliveryPointService.setSdpParentId(site.id);
        }
      }
    });
  }

  get locale() {
    return this.cookieService.getLocale();
  }

  getStatusDisplayLabel(): string {
    if (this.serviceDeliveryPoint && this.serviceDeliveryPoint.status) {
      return this.statuses.find(status => status.id === this.serviceDeliveryPoint.status).display_label;
    }
    return '-';
  }

  async getParent(orgId, sdpId) {
    const allSites = await this.siteService.getSites(orgId);
    const shouldUpdateList = false;
    const forceSelectFirstSite = false;
    if (allSites) {
      for (const site of allSites) {
        const sdpList = await this.serviceDeliveryPointService.getServiceDeliveryPoints(site.id, shouldUpdateList, forceSelectFirstSite);
        const parent = sdpList.filter(sdp => sdp.id === sdpId);
        if (parent.length > 0) {
          return site;
        }
      }
    }
  }

  getLocaleDisplayLabel(): string {
    if (
      this.locales &&
      this.locales.length > 0 &&
      this.serviceDeliveryPoint &&
      this.serviceDeliveryPoint.defaultLocale
    ) {
      return this.locales.find(locale => locale.localeName === this.serviceDeliveryPoint.defaultLocale).displayLabel;
    }
    return '-';
  }

  getTimezoneDisplayLabel(): string {
    if (this.timezones.length > 0 && this.serviceDeliveryPoint && this.serviceDeliveryPoint.timezone) {
      return this.timezones.find(timezone => timezone.timezoneName === this.serviceDeliveryPoint.timezone).displayLabel;
    }
    return '-';
  }

  // TODO: it seems that this function is no longer used
  getSDPData(id, field) {
    if (this.serviceDeliveryPointsList && this.serviceDeliveryPointsList.length > 0) {
      const reg = this.serviceDeliveryPointsList.find(registration => {
        return registration.id === id;
      });
      if (reg && reg.hasOwnProperty(field)) {
        return reg[field];
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  handleCancel() {
    this.router.navigate(['list']);
  }

  openDeleteDialog(): void {
    this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: {
        serviceDeliveryPointId: this.serviceDeliveryPointId,
      },
    });
  }

  handleEdit() {
    this.router.navigate([`details/${this.serviceDeliveryPointId}/edit`]);
  }

  private async loadDynamicLists() {
    this.localesService.setLocales();
    this.timezonesService.setTimezones();
  }
}
